(function($){

    /* ---------------------------------------------- /*
     * Preloader Animation
    /* ---------------------------------------------- */

    $(window).load(function() {
        $('#status').fadeOut();
        $('#preloader').delay(300).fadeOut('slow');
    });


    /* ---------------------------------------------- /*
     * Navigation / Scrolling
    /* ---------------------------------------------- */

    var $footer = $('.footer');


    function atBottom(){
        // document.body.scrollTop alone should do the job but that actually works only in case of Chrome.
        // With IE and Firefox it also works sometimes (seemingly with very simple pages where you have
        // only a <pre> or something like that) but I don't know when. This hack seems to work always.
        var scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;

        // Grodriguez's fix for scrollHeight:
        // accounting for cases where html/body are set to height:100%
        var scrollHeight = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;

        // >= is needed because if the horizontal scrollbar is visible then window.innerHeight includes
        // it and in that case the left side of the equation is somewhat greater.
        return (scrollTop + window.innerHeight) >= scrollHeight;
    }

    function setFooterPos($header, pageHeight, extra){
        var headerTop = $header.position().top;
        if (headerTop == 0){
            $footer.fadeIn();
            if (atBottom()){
                $footer.removeClass('bottom');
            } else {
                $footer.addClass('bottom');
            }

            var footerHeight = $footer.height() + 4;

            $footer.css('position', 'fixed')
                        .css('top', headerTop + pageHeight - footerHeight - extra);
        } else {
            $footer.fadeOut();
        }

    }

    function navBar(){
        var $header = $('.header');
        $header.sticky({
            topSpacing: 0,
            callback: setFooterPos,
            responsiveWidth: true
        });
        $(window).on("orientationchange", function (){
            $header.sticky('update');
        });
        $(window).resize(function (){
            $header.sticky('update');
        });
        $(window).on("touchend", function (){
            $header.sticky('update');
        });
    }

    function smoothScrollLink($applyTo){
        if ($applyTo === undefined){
            $applyTo = $('a[href*=#]');
        }
        $applyTo.bind("click", function(e){
            var anchor = $(this);
            $('html, body').stop().animate({
                scrollTop: $(anchor.attr('href')).offset().top
            }, 1000);
            e.preventDefault();
        });
    }

    function navScrollSpy(){
        // scrollspy from bootstrap
        $('body').scrollspy({
            target: '.navbar',
            offset: 70
        });
    }

    function navScrollButtons(){
        $('.navbar').on('activate.bs.scrollspy', function (e) {
            // hijack scrollspy and get nav link element to current location
            var $current = $(e.target);
            var $prev = $current.prev();
            var $next = $current.next();
            var $up = $('.scroll-up a');
            var $down = $('.scroll-down a');
            // change scroll button links to href for links surrounding current
            // hide scroll button if no surrounding section
            if ($prev.length){
                $up.attr('href', $prev.children('a').attr('href'));
                if (!$up.is(':visible')){
                    $up.fadeIn();
                }
            } else {
                if ($current.children('a').attr('href') != '#home'){
                    $up.attr('href', '#home');
                } else {
                    $up.fadeOut();
                }
            }
            if ($next.length){
                $down.attr('href', $next.children('a').attr('href'));
                if (!$down.is(':visible')){
                    $down.fadeIn();
                }
            } else {
                $down.fadeOut();
            }
        });

        // hide scroll buttons when spash section is full screen
        $(window).scroll(function() {
            if ($(this).scrollTop() > 100) {
                $('.scroller').fadeIn();
                $('.scroll-start').fadeOut();
            } else {
                $('.scroller').fadeOut();
                $('.scroll-start').fadeIn();
            }
        });
    }

    function sectionScrollbutton(){
        var $sections = $('.scrollsection');
        var $up = $('.scroll-up a');
        var $down = $('.scroll-down a');
        smoothScrollLink($up);
        smoothScrollLink($down);

        function getSetScroll(index){
            return function(direction){
                var i = index;
                if (direction == 'up'){
                    i -= 1;
                }
                if (i > 0){
                    $up.attr('href', '#' + $($sections[i-1]).attr('id'));
                    if (!$up.is(':visible')){
                        $up.fadeIn();
                    }
                } else {
                    $up.fadeOut();
                }
                if (i < ($sections.length -1)){
                    $down.attr('href', '#' + $($sections[i+1]).attr('id'));
                        if (!$down.is(':visible')){
                        $down.fadeIn();
                    }
                } else {
                    $down.fadeOut();
                }
            };
        }

        for (var i=0; i<$sections.length; i++){
            $($sections[i]).waypoint(getSetScroll(i), {offset:'50%'});
        }

        $down.attr('href', '#' + $($sections[1]).attr('id'));
        $down.show();
    }

    // /* ---------------------------------------------- /*
    //  * Forms
    // /* ---------------------------------------------- */

    function isValidEmailAddress(emailAddress) {
        var pattern = new RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i);
        return pattern.test(emailAddress);
    }

    function clearValidation(){
        $('input, .recaptcha-validation').parent().removeClass('has_error');
        $('.server_error').remove();
        $('.recaptcha-validation').hide();
    }

    // function expensesFormExtra($form){
    //     var selectedRequest = $form.find('input[name="tags"]:checked').val();
    //     if(selectedRequest == "brochure")
    //     {
    //         $form.find('input[name=campaign_id]').val("c2a95e8a-9ed5-2a98-92c3-551ef024f8ca");
    //         $form.find('input[name=description]').val("Request for an Expenses Brochure");
    //     }
    //     if(selectedRequest == "demo")
    //     {
    //         $form.find('input[name=campaign_id]').val("9d4be132-4524-326d-4bb0-551ef1e1c8a2");
    //         $form.find('input[name=description]').val("Request for an Expenses Demo");
    //     }
    // }

    function validateForm($form){
        var isValid = true;
        // validate

        $form.find('input[required]').each(function(){
            var $this = $(this);
            if (! $this.val().length > 0){
                $this.parent().addClass('has-error');
                isValid = false;
            }
        });

        $form.find('input[type="email"]').each(function(){
            var $this = $(this);
            $this.val($this.val().toLowerCase()) // force lower case for agile crm
            if (! isValidEmailAddress($this.val())){
                $this.parent().addClass('has-error');
                isValid = false;
            }
        });

        if ($form.find('div.g-recaptcha').length){
            var a=grecaptcha.getResponse();
            if(0==a.length){
                isValid = false;
                $('.recaptcha-validation')
                    .show()
                    .parent().addClass('has-error');
            }
        }

        return isValid;
    }

    function formSubmit(e, $thanks, extra){
        // prevent page reload
        e.preventDefault();

        var $form = $(this);

        clearValidation();
        var isValid = validateForm($form);
        if (!isValid){
            return;
        }

        if (extra){
            extra($form);
        }

        // send form via ajax
        var vals = $form.serialize();
        var url = $form.attr("action");
        var post = $.post(url, vals);
        var $error = $('<div class="alert alert-danger server_error" role"alert"></div');

        // display errors or display thank you panel
        post.done(function(data, textStatus, jqXHR){
                // console.log("POST Success", data, textStatus, jqXHR);
                if (jqXHR.status == 200){
                    // remove form and replace with thank you section
                    if ($thanks){
                        $form.fadeOut();
                        $thanks.fadeIn();
                        $(".hide-on-thanks").hide();
                    }
                    // else add a green alert that says thanks, we'll be in touch

                } else {
                    // display non specific error alert
                    $error.text('There was an error. Please try again later');
                    $form.find('input[type="submit"]').after($error);
                }

            })
            .fail(function(data, textStatus, jqXHR){
                // console.log("POST Fail", data, textStatus, jqXHR);
                // error contacting server
                $('.server').css('display', 'block');
                $error.text('There was an error contacting the server. Please try again later');
                $form.find('input[type="submit"]').after($error);
            });
    }

    function googleSheetSubmit(e, $thanks, extra){
        // prevent page reload
        e.preventDefault();
        console.log($(this)[0]);
        var $form = $(this);        
        var $error = $('<div class="alert alert-danger server_error" role"alert"></div');    
    
        clearValidation();
        var isValid = validateForm($form);
        if (!isValid){
            return;
        }        

        if (extra){
            extra($form);
        }

        var recaptcha = $("#g-recaptcha-response").val();            
        if (recaptcha === "") {            
            alert("Please confirm you are not a robot!");
        } else {        
            var scriptURL = 'https://script.google.com/macros/s/AKfycbw5bPbyTYEA0EinyQKVV-HlvC4dsNZtM60T8z0_J8ilghJuX_Y/exec';            
            fetch(scriptURL, { method: 'POST', body: new FormData($form[0])})
                .then(function(response) {
                    console.log('Success!', response);
                    if (response.status == 200){
                    // remove form and replace with thank you section
                        if ($thanks){
                            $form.fadeOut();
                            $thanks.fadeIn();
                            $(".hide-on-thanks").hide();
                        }
                    } else {
                        $error.text('There was an error. Please try again later');
                        $form.find('input[type="submit"]').after($error);
                    }
                })
                .catch(function(error) {
                    console.error('Error!', error.message)
                    $('.server').css('display', 'block');
                    $error.text('There was an error contacting the server. Please try again later');
                    $form.find('input[type="submit"]').after($error);
                });
        }
        
    }

    function agileSubmit(e, $thanks, extra){
        e.preventDefault();

        var $form = $(this);

        clearValidation();
        var isValid = validateForm($form);
        if (!isValid){
            return;
        }

        if (extra){
            extra($form);
        }

        var original_agile_formCallback = agile_formCallback;
        agile_formCallback = function(h, c, a, j, g, d, i){
            if (!h[0]) {
                // no error
                if ($thanks){
                    $form.fadeOut();
                    $thanks.fadeIn();
                    $(".hide-on-thanks").hide();
                }
                // console.log(j.submit);
                // use original callback but prevent from submiting form and leaving page
                // var dummyForm = {
                //     setAttribute: function(){},
                //     getAttribute: function(){},
                //     submit: function(){}
                // };
                // original_agile_formCallback(h, c, a, dummyForm, g, d, i);
                // original_agile_formCallback(h, c, a, j, g, d, i);
            } else {
                // display non specific error alert
                $error.text('There was an error. Please try again later');
                $form.find('input[type="submit"]').after($error);
            }
            agile_formCallback = original_agile_formCallback;
        }

        _agile_synch_form_v4();
    }

    /* ---------------------------------------------- /*
     * WOW Animation When You Scroll
    /* ---------------------------------------------- */

    function wowAnimationScroll(){
        var $html = $('html');

        if ($html.hasClass('ie8') || $html.hasClass('ie9')){
        } else {
            wow = new WOW({
                mobile: false
            });
            wow.init();
        }
    }


    function homePage(){

        /* ---------------------------------------------- /*
         * Skills
        /* ---------------------------------------------- */

        $('#studies').waypoint(function(){
            $('.line-content').addClass('running');
            $('#chart').addClass('running-bar');
            // $('.chart').each(function(){
            //     $(this).easyPieChart({
            //         size:140,
            //         animate: 2000,
            //         lineCap:'butt',
            //         scaleColor: false,
            //         barColor: '#FF5252',
            //         trackColor: 'transparent',
            //         lineWidth: 10
            //     });
            // });

        },{offset:'80%'});

        /* ---------------------------------------------- /*
         * Quote Rotator
        /* ---------------------------------------------- */

        $( '#cbp-qtrotator' ).cbpQTRotator();

        /*----------------------------------------/*
        *   Touchscreen check
        /*----------------------------------------*/

        var hasTouch;
        window.addEventListener('touchstart', function setHasTouch () {
            hasTouch = true;
            // Remove event listener once fired, otherwise it'll kill scrolling
            // performance
            effectBubbaTouchscreen();
            window.removeEventListener('touchstart', setHasTouch);
        }, false);

        // console.log(hasTouch);

        /* ---------------------------------------------- /*
         * Effect bubba removal
        /* ---------------------------------------------- */
        function effectBubbaTouchscreen(){
            if (hasTouch){
                $('.effect-bubba')
                .addClass('touch');
            }
        }

        /* ---------------------------------------------- /*
         * Home BG
        /* ---------------------------------------------- */

        $(".screen-height").height($(window).height());

        $(window).resize(function(){
            $(".screen-height").height($(window).height());
        });

        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
            // have background scroll with page
            $('#home').css({'background-attachment': 'scroll'});
            if (/Chrome/i.test(navigator.userAgent)) {
                $('#line-chart-canvas').removeClass('line-content').html('').addClass('no-animation');
            }
        } else {
            // have background scroll at 20% scroll rate
            $('#home').parallax('50%', 0.2);
        }

        // General Page Stuff

        // $("#index-form").submit(function(e){
        //     formSubmit.call(this, e, $('#index-thankyou'));
        // });

        $("#homePage .google-sheet-form").submit(function(e){
            googleSheetSubmit.call(this, e, $('#index-thankyou'));
        });

        wowAnimationScroll();

        navBar();

        navScrollButtons();
        smoothScrollLink();
        navScrollSpy();
    }

    function subPage(){

        // set active nav link
        var path = window.location.pathname;
        $('.navbar a[href="' + path + '"]').addClass('active');

        // forms

        $('input').change(clearValidation);
        // $("#expenses-form").submit(function(e){
        //     formSubmit.call(this, e, $('#expenses-thankyou'));
        // });

        // $("#expenses-landing-form").submit(function(e){
        //     formSubmit.call(this, e, $('#expenses-landing-thankyou'));
        // });

        // $("#eform-form").submit(function(e){
        //     formSubmit.call(this, e, $('#eform-thankyou'));
        // });
        // $("#attendance-form").submit(function(e){
        //     formSubmit.call(this, e, $('#attendance-thankyou'));
        // });

        // $("#payslip-form").submit(function(e){
        //     formSubmit.call(this, e, $('#payslip-thankyou'));
        // });

        $("#expenses .google-sheet-form").submit(function(e){
            googleSheetSubmit.call(this, e, $('#expenses-thankyou'));
            // agileSubmit.call(this, e, $('#expenses-thankyou'));
        });

        $("#attendance .google-sheet-form").submit(function(e){
            googleSheetSubmit.call(this, e, $('#attendance-thankyou'));
            // agileSubmit.call(this, e, $('#attendance-thankyou'));
        });

        $("#payslip .google-sheet-form").submit(function(e){
            googleSheetSubmit.call(this, e, $('#payslip-thankyou'));
            // agileSubmit.call(this, e, $('#payslip-thankyou'));
        });

        $("#eform .google-sheet-form").submit(function(e){
            googleSheetSubmit.call(this, e, $('#eform-thankyou'));
            // agileSubmit.call(this, e, $('#eform-thankyou'));
        });

        $("#expenses-landing .google-sheet-form").submit(function(e){
            googleSheetSubmit.call(this, e, $('#expenses-landing-thankyou'));
            // agileSubmit.call(this, e, $('#expenses-landing-thankyou'));
        });




        navBar();
        sectionScrollbutton();
        smoothScrollLink();
        wowAnimationScroll();
    }

    /* ---------------------------------------------- /*
     * Page Ready
    /* ---------------------------------------------- */

    $(document).ready(function() {
        var page = $('body').attr('id');
        if(window._agile){
            _agile_load_form_fields();
        }
        if (page == 'homePage'){
            homePage();
        }
        else{
            subPage();
        }

        /*------------------------/*
        *      Cookie Bar
        /*-----------------------*/

        // $.cookieBar({
        //     fixed: true,
        //     element: 'header',
        //     // acceptOnScroll: 200,
        //     message: 'We use cookies to track usage and preferences',
        //     zindex: 1000
        // });

    });

})(jQuery);
